// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Syntax highlighting for JSON objects */
ul.json-dict, ol.json-array {
  list-style-type: none;
  margin: 0 0 0 1px;
  border-left: 1px dotted #5D6D7E;
  padding-left: 2em;
}
.json-string {
  color: #0B7500;
}
.json-literal {
  color: #b568de;
  font-weight: bold;
}

/* Toggle button */
a.json-toggle {
  position: relative;
  color: inherit;
  text-decoration: none;
}
a.json-toggle:focus {
  outline: none;
}
a.json-toggle:before {
  color: #aaa;
  content: "\\25BC"; /* down arrow */
  position: absolute;
  display: inline-block;
  width: 1em;
  left: -1em;
  font-size: 14px;
}
a.json-toggle.collapsed:before {
  content: "\\25B6"; /* left arrow */
  font-size: 14px;
}

/* Collapsable placeholder links */
a.json-placeholder {
  color: #aaa;
  padding: 0 1em;
  text-decoration: none;
}
a.json-placeholder:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/utils/json-viewer/jquery.json-viewer.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;EACE,qBAAqB;EACrB,iBAAiB;EACjB,+BAA+B;EAC/B,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA,kBAAkB;AAClB;EACE,kBAAkB;EAClB,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;EACX,gBAAgB,EAAE,eAAe;EACjC,kBAAkB;EAClB,qBAAqB;EACrB,UAAU;EACV,UAAU;EACV,eAAe;AACjB;AACA;EACE,gBAAgB,EAAE,eAAe;EACjC,eAAe;AACjB;;AAEA,kCAAkC;AAClC;EACE,WAAW;EACX,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":["/* Syntax highlighting for JSON objects */\nul.json-dict, ol.json-array {\n  list-style-type: none;\n  margin: 0 0 0 1px;\n  border-left: 1px dotted #5D6D7E;\n  padding-left: 2em;\n}\n.json-string {\n  color: #0B7500;\n}\n.json-literal {\n  color: #b568de;\n  font-weight: bold;\n}\n\n/* Toggle button */\na.json-toggle {\n  position: relative;\n  color: inherit;\n  text-decoration: none;\n}\na.json-toggle:focus {\n  outline: none;\n}\na.json-toggle:before {\n  color: #aaa;\n  content: \"\\25BC\"; /* down arrow */\n  position: absolute;\n  display: inline-block;\n  width: 1em;\n  left: -1em;\n  font-size: 14px;\n}\na.json-toggle.collapsed:before {\n  content: \"\\25B6\"; /* left arrow */\n  font-size: 14px;\n}\n\n/* Collapsable placeholder links */\na.json-placeholder {\n  color: #aaa;\n  padding: 0 1em;\n  text-decoration: none;\n}\na.json-placeholder:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
